import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthInterface, AuthUserModel } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { HttpClientProvider } from './http-client';
import { LoadingService } from './loading.service';
import { AlertService } from './alert.service';
import { NotificationService } from './notification.service';
import { getEnvironment } from '../environment/environment';

export type UserType = AuthUserModel | null;

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	env = getEnvironment();

	private _user = new BehaviorSubject<UserType>(null);
	user$ = this._user.asObservable();

	get currentUserValue(): UserType {
		return this._user.value;
	}
	set currentUserValue(user: UserType) {
		this._user.next(user);
	}

	headers:any = null;
	
	constructor(
		private httpClient: HttpClient,
		private http: HttpClientProvider,
		private loading: LoadingService,
		private router: Router,
		private alert: AlertService,
		private toast: NotificationService,
	) {
	}

	getAuth(){
		let auth:any = localStorage.getItem('auth') || '';
		if(auth) {
			let _auth:AuthInterface = JSON.parse(auth);
			return _auth;
		} else {
			return null;
		}
	}

	getToken(){
		let auth:any = localStorage.getItem('auth') || '';
		return this._getToken(auth) || null;
	}

	_getToken(auth:AuthInterface){
		if(auth) {
			let _auth:any = auth;
			if(JSON.parse(_auth)?.login) {
				return JSON.parse(_auth).access_token;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	logout(type?:any) {
		if(this.getToken()) {
			this.loading.loadingShow();
			this.http.postRequest(SettingsProvider.getUrl('logout'), {}).subscribe(() => {
				this.loading.dismiss();
				this.clearData();
			}, error => {
				this.loading.dismiss();
				this.clearData();
			});
		} else {
			this.clearData();
		}
		
		if(type&&type == 'expired') {
			// alert('Tu sesión a expirado.');
		}
	}

	clearData() {
		this.currentUserValue = null;
		localStorage.clear();
		this.router.navigateByUrl('/auth');
	}

	clearDataWithoutRedirect() {
		this.currentUserValue = null;
		localStorage.clear();
	}

	successfullOAuthAuthorization(login:boolean, token:any, expires_in:number, user: any) {
		let expireDate = new Date().getTime() + (1000 * expires_in);
		const auth:AuthInterface = { login: login, access_token: token, expires_in: expireDate, user };
		localStorage.setItem('auth', JSON.stringify(auth));
	}

	getUserByToken(): Observable<UserType> {
		const auth = this.getAuth();
		if (!auth || !auth.access_token) {
			return of(null);
		}
		return this._getUserByToken(auth.access_token).pipe(
			map((data: any) => {
				// console.log(data);
				let _data = data['data'];
				let itemData:UserType = {
					..._data['user'],
					membershipCurrent: _data['memberships_current'],
					memberships: _data['memberships'],
					// website: {
					// 	menu: _data['menu'],
					// }
				}
				if(this.env.type == 'club-bolivar') {
					if(_data['lve']) {
						this.alert.alertCapYei({
							type: 'info',
							message: _data.lve['title'],
							buttons: {
								cancel: { show: false, text: 'No' },
								confirm: { show: true, text: 'Sí' },
							},
							description: _data.lve['description']
						}).then(data => {
							if(data.isConfirmed) {
								this.loading.loadingShow();
								this.http.getRequest(SettingsProvider.getUrl('claim-lve')).subscribe({
									next: (res) => {
										this.loading.dismiss();
										if(res?.status) {
											this.getUserByToken().subscribe();
											this.router.navigateByUrl('/pending-payments');
											this.toast.success('Éxito', (res['message'] ?? 'Abono o Sociedad reclamada'), 4000);
										} else{
											this.toast.error('¡Error!', (res['message'] ?? 'Hubo un error al reclamar Abono o Sociedad, intente nuevamente.'), 4000);
										}
									}, error: (err) => {
										this.toast.error('¡Error!', 'Hubo un error al reclamar Abono o Sociedad, intente nuevamente.', 4000);
									}
								})
							}
						})
					}
				}
				return this.userData(itemData);
			})
		);
	}

	_getUserByToken(token: string): Observable<AuthUserModel> {
		// console.log(token);
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
		return this.httpClient.get<AuthUserModel>(SettingsProvider.getAccount(), {
            headers: httpHeaders,
        });
	}

	userData(user: UserType) {
		if (user) {
		  	this.currentUserValue = user;
		} else {
		  	this.logout();
		}
		return user;
	}
}
